import { IconButton, SyncAltIcon } from '@pancakeswap/uikit'
import { CurrencyLogo, DoubleCurrencyLogo } from 'components/Logo'
import { ChartViewMode } from 'state/user/actions'
import { useExchangeChartViewManager } from 'state/user/hooks'
import { Eye, Loader } from 'lucide-react'
import { Separator } from 'components/ui/separator'
import { Badge } from 'components/ui/badge'
import Link from 'next/link'
import BasicChart from './BasicChart'

const PriceChart = ({
  inputCurrency,
  outputCurrency,
  isChartExpanded,
  isMobile,
  token0Address,
  token1Address,
  currentSwapPrice,
}) => {
  if (!inputCurrency || !outputCurrency) {
    return (
      <div className="bg-black rounded-2xl p-4 h-40">
        <div className="flex flex-row items-center justify-between">
          <span className="uppercase text-orange-200 text-2xl font-bold">Pair</span>
          <Loader className=" text-orange-200 h-6 w-6" />
        </div>
      </div>
    )
  }

  function formatSymbol(symbol) {
    return symbol.toLowerCase() === 'wrss3' ? 'RSS3' : symbol
  }

  return (
    <div className="bg-black rounded-2xl p-4">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <div className="text-2xl font-bold text-orange-200">
                {inputCurrency && (
                  <p className="text-orange-200">
                    {outputCurrency
                      ? `${formatSymbol(inputCurrency.symbol)}/${formatSymbol(outputCurrency.symbol)}`
                      : formatSymbol(inputCurrency.symbol)}
                  </p>
                )}
              </div>
              {outputCurrency ? (
                <DoubleCurrencyLogo currency0={inputCurrency} currency1={outputCurrency} size={24} margin />
              ) : (
                inputCurrency && <CurrencyLogo currency={inputCurrency} size="24px" />
              )}
            </div>
            <Eye className=" text-orange-200 h-6 w-6" />
          </div>
          <div className="flex flex-col items-start justify-start gap-1 text-orange-200">
            {currentSwapPrice ? (
              <div className="flex flex-col gap-1 items-start">
                <p className="text-sm">{`1 ${formatSymbol(inputCurrency.symbol)} = ${currentSwapPrice[
                  inputCurrency.address.toLowerCase()
                ].toFixed(4)} ${formatSymbol(outputCurrency.symbol)}`}</p>
                <p className="text-sm">{`1 ${formatSymbol(outputCurrency.symbol)} = ${currentSwapPrice[
                  outputCurrency.address.toLowerCase()
                ].toFixed(4)} ${formatSymbol(inputCurrency.symbol)}`}</p>
              </div>
            ) : (
              <div className="flex flex-col gap-1 items-start">
                <p className="text-sm">{`1 ${formatSymbol(inputCurrency.symbol)} = -- ${formatSymbol(
                  outputCurrency.symbol,
                )}`}</p>
                <p className="text-sm">{`1 ${formatSymbol(outputCurrency.symbol)} = -- ${formatSymbol(
                  inputCurrency.symbol,
                )}`}</p>
              </div>
            )}
          </div>
        </div>
        <BasicChart token0Address={token0Address} token1Address={token1Address} currentSwapPrice={currentSwapPrice} />
      </div>
      {['power', 'wrss3'].includes(inputCurrency.symbol.toLowerCase()) &&
      !!outputCurrency &&
      ['power', 'wrss3', 'rss3'].includes(outputCurrency.symbol.toLowerCase()) ? (
        <div className="pt-2">
          <span className="flex flex-row items-center gap-2">
            <span className="text-xs text-orange-200"> Or...</span>{' '}
            <Link
              href="https://www.geckoterminal.com/zh/rss3-vsl-mainnet/pools/0x5fad18f2ed88cc432dc0e8344a95e4d295672a1f"
              target="_blank"
            >
              <Badge className="bg-orange-200 capitalize">view on geckoterminal</Badge>
            </Link>
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default PriceChart
