import { gql } from 'graphql-request'
import { getMultiChainQueryEndPointWithStableSwap, MultiChainName } from 'state/info/constant'

const FACTORY_INFO = gql`
  query factoryInfo {
    pancakeFactories {
      totalTransactions
      totalVolumeUSD
      totalLiquidityUSD
      totalPairs
    }
  }
`

export const getFactoryInfo = async (chainName: MultiChainName): Promise<{ data: any; error: boolean }> => {
  try {
    const data = await getMultiChainQueryEndPointWithStableSwap(chainName).request<any>(FACTORY_INFO)
    return { data, error: false }
  } catch (error) {
    console.error('Failed to fetch pool chart data', error)
    return { data: undefined, error: true }
  }
}
