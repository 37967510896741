import { useCallback } from 'react'
import { SmartSwapForm } from 'views/Swap/SmartSwap'
import { useSingleTokenSwapInfo, useSwapState } from 'state/swap/hooks'
import { Currency } from '@pancakeswap/sdk'
import useWarningImport from 'views/Swap/hooks/useWarningImport'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import currencyId from 'utils/currencyId'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { ArrowRight, Bolt, Eye } from 'lucide-react'
import Layout from 'components/Common/Layout'
import Link from 'next/link'
import useSWR from 'swr'
import { getFactoryInfo } from 'state/info/queries/factory/info'
import { usePoolsData } from 'views/Info/hooks/usePoolsData'
import SlotCounter from 'react-slot-counter'
import Account from 'components/Common/Account'
import { useModal } from '@pancakeswap/uikit'
import SettingsModal from 'components/Menu/GlobalSettings/SettingsModal'
import { SettingsMode } from 'components/Menu/GlobalSettings/types'
import { Badge } from 'components/ui/badge'
import { useRouter } from 'next/router'
import { useCurrency } from 'hooks/Tokens'
import PriceChartContainer from 'views/Swap/components/Chart/PriceChartContainer'
import { WRSS3_ADDRESS } from 'views/Swap/components/Chart/constants'
import { Field } from '../state/swap/actions'

export default function Index() {
  return (
    <Layout>
      <div className="flex flex-col gap-2 mb-8">
        <Account />
        <Form />
        <Price />
        <Info />
      </div>
    </Layout>
  )
}

function Form() {
  const warningSwapHandler = useWarningImport()
  const { onCurrencySelection } = useSwapActionHandlers()

  // swap state & price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  const handleOutputSelect = useCallback(
    (newCurrencyOutput: Currency) => {
      onCurrencySelection(Field.OUTPUT, newCurrencyOutput)
      warningSwapHandler(newCurrencyOutput)

      const newCurrencyOutputId = currencyId(newCurrencyOutput)
      if (newCurrencyOutputId === inputCurrencyId) {
        replaceBrowserHistory('inputCurrency', outputCurrencyId)
      }
      replaceBrowserHistory('outputCurrency', newCurrencyOutputId)
    },

    [inputCurrencyId, outputCurrencyId, onCurrencySelection, warningSwapHandler],
  )

  const [onPresentSettingsModal] = useModal(<SettingsModal mode={SettingsMode.SWAP_LIQUIDITY} />)

  return (
    <div className="bg-black rounded-2xl px-4 pt-4 pb-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center justify-between">
            <span className="uppercase text-orange-200 text-2xl font-bold">Swap</span>
            <Eye className=" text-orange-200 h-6 w-6" />
          </div>
          <div className="flex flex-row items-center gap-2">
            <span className="text-orange-200">Swap tokens at the best price</span>
            <button className="text-orange-200" type="button" onClick={onPresentSettingsModal}>
              <Bolt size={20} />
            </button>
          </div>
        </div>
        <SmartSwapForm handleOutputSelect={handleOutputSelect} />
      </div>
    </div>
  )
}

function Price() {
  const {
    [Field.INPUT]: { currencyId: inputId },
    [Field.OUTPUT]: { currencyId: outputId },
  } = useSwapState()
  const inputCurrencyId = !inputId || inputId.toLowerCase() === 'rss3' ? WRSS3_ADDRESS : inputId
  const outputCurrencyId = !outputId || outputId.toLowerCase() === 'rss3' ? WRSS3_ADDRESS : outputId
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)

  return (
    <PriceChartContainer
      inputCurrencyId={inputCurrencyId}
      inputCurrency={inputCurrency}
      outputCurrencyId={outputCurrencyId}
      outputCurrency={outputCurrency}
      isFullWidthContainer
      isChartExpanded
      isChartDisplayed
      currentSwapPrice={singleTokenPrice}
    />
  )
}

function Pair() {
  const rss3Pair = '0x6ef455b9514d476448ee6841cdceb35be4817032'
  const { poolsData } = usePoolsData()
  const isLoading = poolsData.length === 0
  const data = poolsData.find((item) => item.address === rss3Pair)

  return (
    <div className="bg-black rounded-2xl p-4">
      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-1">
          <Link href={`/pools/${rss3Pair}`}>
            <div className="flex flex-row items-center justify-between">
              <span className="uppercase text-orange-200 text-2xl font-bold">Pair</span>
              <ArrowRight className=" text-orange-200 h-6 w-6" />
            </div>
          </Link>
          <span className="text-orange-200">RSS3 / USDC - LP APR</span>
        </div>
        <div className="flex border-t border-orange-200 w-full pt-2 text-orange-200">
          <div className="flex flex-row items-end justify-between w-full">
            <div className="text-5xl font-bold">
              {isLoading ? <span>--.---%</span> : <SlotCounter value={`${data.lpApr7d.toFixed(2)}%`} />}
            </div>
            <div className="flex flex-col items-end -spay0">
              {isLoading ? '≈ $--.----' : <SlotCounter value={`≈ $${data.token0Price.toFixed(4)}`} />}
              <span className="font-bold">RSS3</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function Info() {
  const { pathname } = useRouter()
  const { data: raw, isLoading } = useSWR(
    ['factory', 'info'],
    async () => {
      const res = await getFactoryInfo('RSS3')
      return res
    },
    { refreshInterval: 60 * 1000 },
  )

  const data = isLoading ? undefined : raw?.data.pancakeFactories[0]

  return (
    <div className="bg-black rounded-2xl p-4 min-h-40">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-1">
          <Link href="/pools">
            <div className="flex flex-row items-center justify-between">
              <span className="uppercase text-orange-200 text-2xl font-bold">Info</span>
              {pathname === '/' ? (
                <ArrowRight className=" text-orange-200 h-6 w-6" />
              ) : (
                <Eye className=" text-orange-200 h-6 w-6" />
              )}
            </div>
          </Link>
          <span className="text-orange-200">Discover cryptos at RSS3 VSL.</span>
        </div>
        <div className="flex flex-col gap-4">
          <Link href="/liquidity/add">
            <Badge className="bg-orange-200 capitalize">Create new Pair</Badge>
          </Link>
          <div className="flex border-t border-orange-200 w-full pt-2">
            <div className="flex flex-col gap-0.5 text-orange-200 w-full">
              <div className="flex flex-row items-center justify-between">
                <span className="text-sm">Total Volume:</span>
                <span className="text-sm">
                  <SlotCounter value={data ? `$${parseFloat(data.totalVolumeUSD).toFixed(2)}` : ' $--'} />
                </span>
              </div>
              <div className="flex flex-row items-center justify-between">
                <span className="text-sm">Total Liquidity:</span>
                <span className="text-sm">${data ? parseFloat(data.totalLiquidityUSD).toFixed(2) : ' --'}</span>
              </div>
              <div className="flex flex-row items-center justify-between">
                <span className="text-sm">Total Transactions:</span>
                <span className="text-sm">{data ? Number(data.totalTransactions).toLocaleString() : '--'}</span>
              </div>
              <div className="flex flex-row items-center justify-between">
                <span className="text-sm">Total Pairs:</span>
                <span className="text-sm">{data ? data.totalPairs : '--'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
