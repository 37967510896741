import { useState, memo } from 'react'
import { useFetchPairPrices } from 'state/swap/hooks'
import { PairDataTimeWindowEnum } from 'state/swap/types'
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from 'components/ui/chart'

const BasicChart = ({ token0Address, token1Address, currentSwapPrice }) => {
  const [timeWindow, setTimeWindow] = useState<PairDataTimeWindowEnum>(0)

  const { pairPrices = [], pairId } = useFetchPairPrices({
    token0Address,
    token1Address,
    timeWindow,
    currentSwapPrice,
  })
  // Sometimes we might receive array full of zeros for obscure tokens while trying to derive data
  // In that case chart is not useful to users
  // const isBadData =
  //   pairPrices &&
  //   pairPrices.length > 0 &&
  //   pairPrices.every(
  //     (price) => !price.value || price.value === 0 || price.value === Infinity || Number.isNaN(price.value),
  //   )
  const isBadData = false

  if (isBadData) {
    return <div className="h-24" />
  }

  const data = pairPrices.map((item: any) => {
    const time = new Date(item.time).getTime() / 1000
    return {
      time,
      value: item.value,
    }
  })

  return <PriceDiagram data={data} />
}

const chartConfig = {
  value: {
    label: 'price',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig

export function PriceDiagram({
  data,
}: {
  data: {
    time: number
    value: number
  }[]
}) {
  const raw = data.map((item) => ({ ...item, value: item.value === Infinity ? 0 : item.value }))
  return (
    <ChartContainer config={chartConfig} className="aspect-auto h-24 w-full">
      <AreaChart
        accessibilityLayer
        data={raw}
        margin={{
          top: 6,
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="time"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={(value) => {
            const date = new Date(value * 1000)
            return date.toLocaleDateString('en-US', {
              day: 'numeric',
              hour: 'numeric',
              hour12: true,
            })
          }}
        />
        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              labelFormatter={(_, props) => {
                return new Date(props[0].payload.time * 1000).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })
              }}
            />
          }
        />
        <Area dataKey="value" type="natural" fill="var(--color-value)" fillOpacity={0} stroke="var(--color-value)" />
      </AreaChart>
    </ChartContainer>
  )
}

export default memo(BasicChart, (prev, next) => {
  return (
    prev.token0Address === next.token0Address &&
    prev.token1Address === next.token1Address &&
    ((prev.currentSwapPrice !== null &&
      next.currentSwapPrice !== null &&
      prev.currentSwapPrice[prev.token0Address] === next.currentSwapPrice[next.token0Address] &&
      prev.currentSwapPrice[prev.token1Address] === next.currentSwapPrice[next.token1Address]) ||
      (prev.currentSwapPrice === null && next.currentSwapPrice === null))
  )
})
