import Pending from "./_images/pending.svg";

export function ConfirmationPendingContent({ pendingText }: { pendingText?: string }) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 min-h-44">
      <div className="h-52 w-52 flex items-center justify-center">
        <span className="text-7xl font-bold">🤔</span>
      </div>
      <div className="flex flex-col items-center justify-center gap-2  text-orange-200">
        <div className="text-balance text-center">{pendingText || "Waiting for confirmation..."}</div>
        <div className="opacity-60"> Confirm this transaction in your wallet</div>
      </div>
    </div>
  );
}
